import React from 'react'
import { Document } from '@contentful/rich-text-types'
import { graphql } from 'gatsby'
import {
  Layout,
  PublishedData,
  RichText,
  SinglePageContainer,
} from '../components/common'
import styled from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'
import { ROUTES } from '../constants/routes'

type PostProps = {
  data: {
    post: {
      title: string
      groups: [
        {
          initials: string
          name: string
          slug: string
        }
      ]
      content: {
        json: Document
      }
      createdAt: string
      thumbnail: {
        fluid: FluidObject
      }
    }
  }
}

const Post: React.FC<PostProps> = ({ data }) => (
  <Layout navigation={{ location: ROUTES.POST }}>
    <SinglePageContainer>
      <StyledPost>
        <StyledHeader>
          <PostTitle>{data.post.title}</PostTitle>
          <PublishedData
            createdAt={data.post.createdAt}
            groups={data.post.groups}
          />
          <Img fluid={data.post.thumbnail.fluid} />
        </StyledHeader>
        <RichText content={data.post.content.json} />
      </StyledPost>
    </SinglePageContainer>
  </Layout>
)

export default Post

export const pageQuery = graphql`
  query Post($id: String!) {
    post: contentfulPost(id: { eq: $id }) {
      title
      groups {
        initials
        name
        slug
      }
      content {
        json
      }
      createdAt
      thumbnail {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

const StyledPost = styled.article`
  margin: 3rem 1rem;

  @media only screen and (min-width: 1024px) {
    margin: 3rem 12rem;
  }
`

const StyledHeader = styled.header`
  margin-bottom: 2rem;
`

const PostTitle = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
`
